import * as React from "react";
import Store from '../store/store';
import { observer } from "mobx-react";
import { IDBWorkflow, IDBWfStep, IDBWfTask } from "./interfaces/IWorkflow";
import styles from "./styles/workflowNew.module.scss";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import Orientation from "./Orientation";
import Signature from "./Signature";
import Check from '@material-ui/icons/Check';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "./../helper/helpers";
import PopoverTaskCollab from "../popover/PopoverTaskCollab";

interface IWorkflowProps {
  workflow: IDBWorkflow;
  mode: string;
  idSelectedStep: number;
  idSelectedTask?: number;
}

interface IWorkflowState {
  toggleStep: number[];
  isOpenPopover?: boolean
  userOfGroupPopover?: number[]
  idGroup?: number
}

interface State {


}

@observer
class Workflow extends React.Component<RouteComponentProps & IWorkflowProps, IWorkflowState>{
  constructor(props) {
    super(props);

    this.state = {
      toggleStep: [],
      isOpenPopover: false,
      userOfGroupPopover: [],
      idGroup: undefined
    };
  }

  public componentDidMount() {
    if (this.props.idSelectedStep != undefined && this.props.workflow.id != undefined) {
      const step = this.props.workflow.WfSteps.find(step => step.id == this.props.idSelectedStep);
      let task = step.WfTasks[0];

      const nextStep = this.props.workflow.WfSteps.find(s => s.rankStep == step.rankStep + 1);
      if (this.props.idSelectedTask != undefined) {
        task = step.WfTasks.find(ta => ta.id == this.props.idSelectedTask);
      }
      let toggleStep = [];
      if (step != null && task != null) {
        this.selectTask(task, step, true);
        toggleStep.push(step.id);
        if (step.state == "En cours" && nextStep != null) {
          toggleStep.push(nextStep.id);
        }
      }
      this.setState({ toggleStep: toggleStep });
    }
    else if (this.props.workflow.id != undefined && this.props.workflow.id != null) {
      const checkTask = this.checkIfTaskForUserConnected(this.props.workflow, Store.userConnected.id);
      const nextTask = this.checkNextTaskForUserConnected(this.props.workflow, checkTask);
      const toggleStep = [];
      if (checkTask != null) {
        this.selectTask(checkTask.task, checkTask.step, true);
        toggleStep.push(checkTask.step.id);
        if (checkTask.step.state === "En cours" && nextTask != null) {
          toggleStep.push(nextTask.step.id);
        }
      }
      this.setState({ toggleStep: toggleStep });
    }
  }

  componentDidUpdate(prevProps: IWorkflowProps, prevState: IWorkflowState) {

    if (((this.props.idSelectedStep != undefined && this.props.idSelectedStep != null && this.props.idSelectedStep != prevProps.idSelectedStep) || (this.props.idSelectedTask != undefined && this.props.idSelectedTask != null && this.props.idSelectedTask != prevProps.idSelectedTask))) {
      const step = this.props.workflow.WfSteps.find(step => step.id == this.props.idSelectedStep);
      if (step != undefined && step.WfTasks != undefined && step.WfTasks != null && step.WfTasks.length > 0) { // askip c'est possible (nicolas)
        let task = step.WfTasks[0];
        const nextStep = this.props.workflow.WfSteps.find(s => s.rankStep == step.rankStep + 1);
        if (this.props.idSelectedTask != undefined) {
          task = step.WfTasks.find(ta => ta.id == this.props.idSelectedTask);
        }
        let toggleStep = [];
        if (step != null && task != null) {
          this.selectTask(task, step, true);
          toggleStep.push(step.id);
          if (step.state == "En cours" && nextStep != null) {
            toggleStep.push(nextStep.id);
          }
        }
        this.setState({ toggleStep: toggleStep });
      }
    }
    else if ((this.props.workflow.id != undefined && this.props.workflow.id != null) && (this.props.workflow.id != prevProps.workflow.id || (this.props.workflow.id != undefined && this.props.workflow.id != null && this.props.workflow.id == prevProps.workflow.id && this.props.workflow.WfSteps.filter(st => st.state == "En cours")[0] != undefined && prevProps.workflow.WfSteps.filter(st => st.state == "En cours")[0] != undefined && this.props.workflow.WfSteps.filter(st => st.state == "En cours")[0].id !== prevProps.workflow.WfSteps.filter(st => st.state == "En cours")[0].id))) {
      const checkTask = this.checkIfTaskForUserConnected(this.props.workflow, Store.userConnected.id);
      const nextTask = this.checkNextTaskForUserConnected(this.props.workflow, checkTask);
      const toggleStep = [];
      if (checkTask != null) {
        this.selectTask(checkTask.task, checkTask.step, true);
        toggleStep.push(checkTask.step.id);
        if (checkTask.step.state == "En cours" && nextTask != null) {
          toggleStep.push(nextTask.step.id);
        }
      } else {
        Store.myStepTaskSelectionned = undefined;
      }
      this.setState({ toggleStep: toggleStep });
    }
  }

  public checkIfTaskForUserConnected = (workflow: IDBWorkflow, idUserConnected: number): { step: IDBWfStep, task: IDBWfTask } | null => {
    if (workflow.id != undefined && workflow.id != null && idUserConnected != undefined && idUserConnected != null) {
      const stepEnCours: IDBWfStep[] = workflow.WfSteps.filter(step => step.state == "En cours");
      if (stepEnCours.length > 0) {
        const myTaskEnCours: IDBWfTask[] = stepEnCours[0].WfTasks.filter(task => {
          if (task.state == "En cours") {
            if (task.userIdx == idUserConnected) {
              return true
            }
            // else if (task.User != undefined && task.User != null && task.User.isGroup) {
            //   if (task.editedGroup != undefined && task.editedGroup != null) {
            //     return task.editedGroup.find(userOfGroup => userOfGroup == idUserConnected) != undefined;
            //   } else if (task.User.usersOfGroup != undefined && task.User.usersOfGroup != null) {
            //     return task.User.usersOfGroup.find(userOfGroup => userOfGroup == idUserConnected) != undefined;
            //   }
            // }
          }
        });
        if (myTaskEnCours.length > 0) {
          return {
            step: stepEnCours[0],
            task: myTaskEnCours[0]
          }
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  public checkNextTaskForUserConnected = (workflow: IDBWorkflow, stepNtask): { step: IDBWfStep, task: IDBWfTask } | null => {
    if (workflow.id != undefined && workflow.id != null && stepNtask != null) {
      const nextStep: IDBWfStep[] = workflow.WfSteps.filter(step => step.rankStep == stepNtask.step.rankStep + 1);
      if (nextStep.length > 0 && nextStep[0].WfTasks.length > 0) {
        return {
          step: nextStep[0],
          task: nextStep[0].WfTasks[0]
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  // Fonction appelée pour plier ou déplier une étape
  public toggleStep = (idStep: number) => event => {
    const findStep = this.state.toggleStep.filter(idS => idS == idStep);
    let stepActual = this.state.toggleStep;
    if (findStep.length > 0) {
      const findStep = stepActual.filter(step => step != idStep);
      stepActual = findStep;
    }
    else {
      stepActual.push(idStep);
    }
    this.setState({ toggleStep: stepActual });

    const query = useQuery();
    const s = query.get('s');
    if (s != undefined && s != null && s.length > 0) {
      query.set("s", idStep.toString());
    }
    else {
      query.append("s", idStep.toString());
    }

    const t = query.get('t');
    if (t != undefined && t != null && t.length > 0) {
      query.delete("t");
    }
    window.history.replaceState(null, null, '/document?' + query);


    // const query = useQuery()
    // const idWorkflow: number = parseInt(query.get("idWorkflow"));
    // const idW: number = parseInt(query.get('w'));
    // let wf: string= "w=";
    // if (isNaN(idWorkflow) == false) {
    //   wf += idWorkflow;
    // }
    // else if (isNaN(idW) == false){
    //   wf += idW;
    // }
    // const getTask = parseInt(query.get('t'));
    // const task = (!isNaN(getTask)) ? "&t="+getTask : '';
    // const getBannette= parseInt(query.get('b'));
    // const bannette = (!isNaN(getBannette)) ? "&b="+getBannette : '';
    // const toString = wf+"&s="+idStep+task+bannette;
    // const s = query.get('s');
    // if(s != undefined && s != null && s.length > 0) {
    //   query.set("s",idStep.toString());
    // }
    // else {
    //   query.append("s",idStep.toString());
    // }
    // window.history.replaceState(null, null,'/document?'+query);
  }

  // Fonction pour convertir les dates pour l'affichage
  public convertDateToDisplay = (date) => {
    if (date != undefined && date != null) {
      const theDate = new Date(date);
      const day = theDate.getDate() >= 10 ? theDate.getDate() : "0" + theDate.getDate();
      const month = (theDate.getMonth() + 1) >= 10 ? (theDate.getMonth() + 1) : "0" + (theDate.getMonth() + 1);
      return day + "/" + month + "/" + theDate.getFullYear();
    }
    else {
      return "";
    }
  }

  public convertNameToCollab = (task: IDBWfTask, step:IDBWfStep) => {
    if (task.User != undefined && task.User != null) {
      if (step.isCollab == true && task.userIdx != Store.userConnected.id && step.WfTasks.length > 1) {
        if(step.isStepOrientation == true) {
          return { name: "Étape d'orientation", email: "" };
        }
        else {
          return { name: "Étape collaborative", email: "" };
        }
      }
      else {
        let titleHover = task.User.email
        if (task.isDelegate && task.UserFrom != undefined && task.UserFrom != null) {
          titleHover = "\'" + task.User.email + "\'" + " délégué(e) de " + task.UserFrom.firstname + " " + task.UserFrom.lastname
        }
        return { name: task.User.firstname + " " + task.User.lastname, email: titleHover }
      }
    } else {
      return { name: "utilisateur introuvable", email: "utilisateur introuvable" }
    }
  }

  public taskSelectable = (step, task): boolean => {
    // if (task.state == "Terminée" || step.state == "Refusé" || step.state == "Annulé" ) {
    //   return false;
    // }
    // else {
    //   return true;
    // }
    return true;
  }

  // Fonction appelée lorsqu'on selectionne une tache dans le workflow
  public selectTask = (task: IDBWfTask, step: IDBWfStep, init: boolean) => {
    Store.contextualmenu = { content: "ActionsWorkflow", open: "open" };
    if (Store.myStepTaskSelectionned == undefined || Store.myStepTaskSelectionned.id != task.id) {
      const tempObject = { ...task };
      tempObject["WfStep"] = { ...step };
      // tempObject.WfStep.WfTasks = [];
      Store.myStepTaskSelectionned = {...tempObject};
      // Store.taskInfoForPostIt = tempObject;
      // Store.taskPostIt = "view";
    }

    // const query = useQuery();
    // const idWorkflow: number = parseInt(query.get("idWorkflow"));
    // const idW: number = parseInt(query.get('w'));
    // // Si il y a un id on récupére le projet avec cet id
    // let wf: string = 'w=';
    // if (isNaN(idWorkflow) == false) {
    //   wf += idWorkflow;
    // }
    // else if (isNaN(idW) == false){
    //   wf += idW;
    // }
    // const getBannette= query.get('b');
    // const bannette = (getBannette != undefined)? "&b="+getBannette : '';
    // const toString = wf+"&s="+step.id+"&t="+task.id+bannette;
    // window.history.replaceState(null, null,'/document?'+toString);

    if (init == false) {
      const query = useQuery();
      const s = query.get('s');
      if (s != undefined && s != null && s.length > 0) {
        query.set("s", step.id.toString());
      }
      else {
        query.append("s", step.id.toString());
      }

      const t = query.get('t');
      if (t != undefined && t != null && t.length > 0) {
        query.set("t", task.id.toString());
      }
      else {
        query.append("t", task.id.toString());
      }
      window.history.replaceState(null, null, '/document?' + query);
    }

    // else if (Store.myStepTaskSelectionned.isStepOrientation && Store.choose == false) {
    //   this.modalOpen();
    // }
    // else {
    //   // Store.myStepTaskSelectionned = undefined; A voir Si ça Marche
    //   Store.taskPostIt = undefined;
    //   // Store.TinyContextualmenu.visible = false
    //   Store.contextualmenu.open = "hidden";
    //   Store.contextualmenu.content = "";
    //   if (Store.workflow.id == undefined) {
    //     Store.contextualmenu.content = "documentVersion";
    //     Store.contextualmenu.open = "extended" // test Thomas
    //   }
    // }
    // // PERMET DE GARDER LE MENU OUVERT SUR LA DERNIERE
    // if (Store.boostrapData.length == 1 || (Store.boostrapData.length > 1 && this.state.unselectedRows.length >= 1)) {
    //   Store.openMenuForOne = true;
    //   Store.contextualmenu.content = "ActionsWorkflow";
    //   Store.contextualmenu.open = "open";
    // }
  }

  public renderTask = (task:IDBWfTask, step:IDBWfStep) => {
    
      const dateBegin = step.realDateBegin;
      const dateFin = task.dateDone;
      let stateTask = task.state;


      if (task.state != "Terminée" && task.isReturned == true) {
        stateTask = "Retourné";
      }

      const taskSelectable: boolean = this.taskSelectable(step, task);
      let classNamesSelectable: string = "";
      const onclickParam: any = {};

      // If pour savoir si c'est une tache retournée pour lui changer la couleur
      if (task.isReturned) {
        classNamesSelectable = classNamesSelectable + " " + styles.returned;
      }

      if (taskSelectable == true && (this.props.mode === undefined || this.props.mode != "view")) {
        onclickParam["onClick"] = () => { this.selectTask(task, step, false) };
        classNamesSelectable = classNamesSelectable + " " + styles.taskSelectable;
      }
      if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.id == task.id) {
        classNamesSelectable = classNamesSelectable + " " + styles.taskSelected;
      }

      const infoUser = this.convertNameToCollab(task, step)

      return (
        <TableRow {...onclickParam} className={styles.tableRowContent + " " + classNamesSelectable} key={task.id}>
          <TableCell style={{ width: "20px" }} align="center">{task.state == "Terminée" ? <Check className={styles.iconStatutTask + " " + styles.iconStatutFinish} /> : ""}</TableCell>
          {step.isCollab == true && step.WfTasks.length > 1 ?
            <TableCell title={infoUser.email} align="left">
              <div style={{ display: "flex" }}>
                <div>{infoUser.name}</div>
                <div style={{ marginLeft: "5px", marginTop: "-3px", height: "18px" }}>
                  <PopoverTaskCollab step={step} />
                </div>
              </div>
            </TableCell>
            :
            <TableCell title={infoUser.email} align="left" style={task.isDelegate ? { fontStyle: 'italic' } : {}}>
              {infoUser.name}
            </TableCell>
          }
          <TableCell align="left">{stateTask}</TableCell>
          <TableCell align="left">{this.convertDateToDisplay(dateBegin)}</TableCell>
          <TableCell align="left">{this.convertDateToDisplay(dateFin)}</TableCell>
          <TableCell style={{ width: "20px" }} align="center">{task.typeTask.label != "Contribution" ? <VisibilityOutlined className={styles.iconStatutTask} /> : ""}</TableCell>
        </TableRow>)
  }
  

  render() {
    if (this.props.workflow.id != undefined && this.props.workflow.id != null) {
      const responseSteps = this.props.workflow.WfSteps.filter(wfs =>  wfs.isResponseStep == true);
      let lastIdStep = undefined;
      if(responseSteps.length>0){
        lastIdStep = responseSteps[responseSteps.length-1].id;
      }
      return (
        <div className={styles.workflowContainer}>
          <div className={styles.stepsContainer}>
            {
              this.props.workflow.WfSteps.map((step: IDBWfStep, index: number) => {
                // Déclaration de variable pour les couleurs etc liai au statut de l'étape
                let colorState: string = "";
                let classPuce: string = "";
                let colorTitle: string = "";
                let colorTimeline: string = styles.default;
                let colorProgressTimeLine: string = "";

                const asTaskReturned: boolean = step.WfTasks.filter(ta => ta.isReturned == true).length > 0 ? true : false;

                // If pour les couleurs par rapport aux états
                if (asTaskReturned == true) {
                  colorState = styles.returned;
                }
                else if (step.state == "En cours") {
                  colorState = styles.enCours;
                  classPuce = styles.stepEnCours;
                  colorTitle = styles.enCours;
                  colorProgressTimeLine = styles.enCours;
                }
                else if (step.state == "Terminée") {
                  colorState = styles.finish;
                  classPuce = styles.stepValidate;
                  colorTimeline = styles.finish;
                }

                // Mise à jour de l'état en fonction des taches et de la step
                let stateStep: string = step.state;
                if (asTaskReturned == true) {
                  stateStep = "Retourné";
                }
                else if (step.state == "En préparation") {
                  stateStep = "En attente";
                }
                const isInDirectTask = step.WfTasks.find(task => task.userIdx === Store.userConnected.id && task.anb != true) != undefined
                // let isInGroupTask = false;
                // const groupTask = step.WfTasks.filter(task => task.User.isGroup);
                // if (groupTask.length > 0) {
                //   isInGroupTask = groupTask.filter(task => {
                //     let group = Store.allUsers.find(user => user.id === task.userIdx)
                //     if (group != undefined) {
                //       let isIn = group.usersOfGroup.find(userOfGroup => userOfGroup == Store.userConnected.id);
                //       if (isIn != undefined) {
                //         return true;
                //       }
                //       else {
                //         return false;
                //       }
                //     } else {
                //       return false;
                //     }
                //   }).length > 0;
                // }
                const gestionnaireDossierId  = this.props.workflow.Document.userId
                let showOrientation: boolean = step.isStepOrientation == true && (stateStep == "En cours") && ( Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" || isInDirectTask /* || isInGroupTask */ || Store.userConnected.id == gestionnaireDossierId ); 
                let showSignature: boolean = step.isSignature == true && ( Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" || isInDirectTask /* || isInGroupTask */ || Store.userConnected.id == gestionnaireDossierId ); 
                
                const percentTaskEnd: number = step.WfTasks != undefined && step.WfTasks != null ? (step.WfTasks.filter(ta => ta.state == "Terminée" && ta.anb == false).length / step.WfTasks.filter(ta => ta.anb == false).length) * 100 : 0;
                let test:any = {};
                if (step.isResponseStep == true) {
                  test = {style:{marginLeft: "34px"}};
                }
                
                return (
                  <div className={styles.stepContainer} {...test} key={step.id}>
                    {/* {step.isResponseStep ? 
                        <div className={styles.timelineLineResponse}></div>
                      :
                        <React.Fragment></React.Fragment>
                    } */}
                    {
                      (lastIdStep != undefined && step.id == lastIdStep) || (index == this.props.workflow.WfSteps.length - 1 )  ?
                        ""
                        :
                        <React.Fragment>
                          <div className={styles.timelineLine + " " + colorTimeline}>
                            {
                              step.state == "En cours" ?
                                <div style={{ height: percentTaskEnd + "%" }} className={styles.timelineProgress + " " + colorProgressTimeLine}></div>
                                :
                                ""
                            }
                          </div>
                          
                        </React.Fragment>
                    }
                    <div className={styles.step}>
                      <div className={styles.stepHeader + " " + colorTitle} onClick={this.toggleStep(step.id)}>
                        <div className={styles.stepPuce + " " + classPuce}>
                          {
                            stateStep == "Terminée" ? <Check className={styles.iconStatut} /> : ""
                          }
                        </div>
                        <div className={styles.stepTitle}>
                          {step.name}
                          <span className={styles.stepStatut + " " + styles.default + " " + colorState}>{stateStep}</span>
                        </div>
                      </div>
                      <div style={this.state.toggleStep.filter(stepId => stepId == step.id).length > 0 ? { height: "auto", opacity: 1 } : {}} className={styles.tasksContainer}>
                        <Table size="small" aria-label="Workflow table">
                          <TableHead>
                            <TableRow className={styles.tableHeader}>
                              {/* <TableCell align="left">Tâche</TableCell> */}
                              <TableCell align="left"></TableCell>
                              <TableCell align="left">
                                Utilisateur
                              </TableCell>
                              <TableCell align="left">Etat</TableCell>
                              <TableCell align="left">Début</TableCell>
                              <TableCell align="left">Fin</TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              step.isCollab == true && step.WfTasks.length > 1 ?
                                step.WfTasks.filter(taskm => taskm.userIdx == Store.userConnected.id).length > 0 ?
                                  this.renderTask(step.WfTasks.filter(taskm => taskm.userIdx == Store.userConnected.id)[0], step)
                                :
                                this.renderTask(step.WfTasks[0], step)
                              :
                              step.WfTasks.map((task: IDBWfTask) => {
                                return this.renderTask(task, step);
                              })
                            }
                          </TableBody>
                        </Table>

                        {showOrientation ?
                          <Orientation mode={this.props.mode} workflow={this.props.workflow} selectedStep={step} childModels={Store.allModelWf.filter(am => am.idParent != null && am.idParent == step.idModel)} />
                          : ""
                        }
                        {showSignature ?
                           <Signature mode={this.props.mode} workflow={this.props.workflow} selectedStep={step} childModels={Store.allModelWf.filter(am => am.idParent != null && am.idParent == step.idModel)} />
                           : ""

                        }
                        { step.isResponse == true && stateStep != "Terminée" && stateStep != "Refusé"?
                            <div>
                              Cette étape peut accueillir une réponse
                            </div>
                          :<React.Fragment></React.Fragment>

                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={styles.workflowContainer}>
          <p>Pas de WorkFlow pour le moment</p>
        </div>
      )
    }
  }
}

export default withRouter(Workflow);